<template>
  <div class="preview">
    <div id="pageContainer" class="container" ref="container" :style="`margin:0 auto; width:${pdfWidth};`">
      <!-- <canvas v-for="page in pdfPages" :key="page" :id="'pdfCanvas' + page"></canvas> -->
      <canvas id="pdfCanvas"></canvas>
    </div>
    <div class="bottom-bar">
      <div @click="handlePrev">上一页</div>
      <div>第 {{ pageIndex + 1 }} / {{ pdfPages }} 页</div>
      <div @click="handleNext">下一页</div>
    </div>
    <van-popup v-model="showMenu" position="left" style="height: 100%; overflow: auto">
      <div class="menu">
        <van-cell class="cell" v-for="(item, index) in category" :key="item.id" :title="item.parentName"
          @click="handleSwitchCurrent(index)" />
      </div>
    </van-popup>
    <div class="menu-btn" @click="showMenu = !showMenu">菜单</div>
  </div>
</template>

<script>
import { Popup, Cell } from "vant";
import api from "@/apis";
const PDFJS = require("pdfjs-dist");
import "pdfjs-dist/web/pdf_viewer.css";

PDFJS.GlobalWorkerOptions.workerSrc =
  "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/build/pdf.worker.min.js";

export default {
  name: "PreviewData",
  components: {
    "van-popup": Popup,
    "van-cell": Cell,
  },
  data() {
    return {
      showMenu: false,
      category: [],
      current: {},
      currentIndex: 0,
      pdfPages: [], // 页数
      pdfWidth: "", // 宽度
      pdfSrc: "", // 地址
      pdfDoc: "", // 文档内容
      pdfScale: 1.0, // 放大倍数
      loading: false,
      pageIndex: 0,
      componentKey: 0
    };
  },
  watch: {
    current: {
      handler(val) {
        if (val.fileaddress) {
          this.loadFile(val.fileaddress);
        }
      },
    },
    pageIndex: {
      handler(val) {
        this.renderPage(val + 1)
      }
    }
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    // async setPdf(path) {
    //   let pdfViewer = new PDFViewer({
    //     container: this.$refs.container,
    //   });
    //   let pdf = await pdfjsLib.getDocument(path);
    //   console.log(pdf);
    //   pdfViewer.setDocument(pdf);
    // },
    initTurn() {
      // if (this.inited) {
      //   $("#book").turn("destroy");
      //   console.log('destroy turn')
      // }
      // console.log(this.isPrint, this.isPrint === '1')
      this.$nextTick(() => {
        $("#pageContainer").turn({
          autoCenter: true,
          display: "single",
          page: this.pageIndex,
          direction: this.$config.cover ? "rtl" : "ltr",
          when: {
            turning: (e, page, pageObj) => {
              this.pageIndex = Number(page);
            },
          },
        });
      });
      this.inited = true;
    },
    loadFile(url) {
      this.loading = true;
      let loadingTask = PDFJS.getDocument(url);
      loadingTask.promise.then((pdf) => {
        this.pdfDoc = pdf;
        this.pdfPages = pdf.numPages;
        this.$nextTick(() => {
          this.loading = false;
          this.renderPage(1);
        });
      });
    },
    renderPage(num) {
      const that = this;
      this.pdfDoc.getPage(num).then((page) => {
        console.log(page);
        let canvas = document.getElementById("pdfCanvas");
        let ctx = canvas.getContext("2d");
        let dpr = window.devicePixelRatio || 1;
        let bsr =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1;
        let ratio = dpr / bsr;
        let scale = 1;
        let viewport = page.getViewport(scale);
        if (viewport.width > window.innerWidth) {
          scale = (window.innerWidth / viewport.width).toFixed(1);
          viewport = page.getViewport(scale);
        }
        canvas.width = viewport.width * ratio;
        canvas.height = viewport.height * ratio;

        canvas.style.width = viewport.width + "px";

        that.pdfWidth = viewport.width + "px";

        canvas.style.height = viewport.height + "px";

        canvas.style.display = 'block'

        ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
        // 将 PDF 页面渲染到 canvas 上下文中
        let renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        page.render(renderContext);
        // if (this.pdfPages > num) {
        //   this.renderPage(num + 1);
        // } else {
        //   this.loading = false
        // }
      });
    },
    handleSwitchCurrent(index) {
      this.current = this.category[index];
      this.currentIndex = index
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.showMenu = false
      this.pageIndex = 0
    },
    traverse(tree, target) {
      if (tree.catalogContent) {
        Object.values(tree.catalogContent).forEach((subItem) => {
          target.push({
            ...subItem,
            parentName: tree.name,
          });
        });
      }

      if (tree.child) {
        tree.child.forEach(child => {
          this.traverse(child, target)
        })
      }
    },
    getCategory() {
      const genealogyId = this.$route.query.genealogyId;
      const categoryData = [];
      api
        .previewData({
          genealogyId,
          token: this.$route.query.token
        })
        .then((data) => {
          let tree = []
          Object.values(data).forEach((item) => {
            const finded = tree.findIndex(treeItem => treeItem.id === item.parentId)
            if (finded >= 0) {
              if (!tree[finded].child) {
                tree[finded].child = []
              }
              tree[finded].child.push(item)
              tree[finded].child = tree[finded].child.sort((a, b) => {
                if (a.orderId !== b.orderId) return a.orderId - b.orderId
                return a.id - b.id
              })
            } else {
              const inRest = data[item.parentId]

              if (inRest) {
                if (!inRest.child) {
                  inRest.child = []
                }
                inRest.child.push(item)
                tree.push(inRest)
              } else {
                tree.push(item)
              }

              tree = tree.sort((a, b) => {
                if (a.orderId !== b.orderId) return a.orderId - b.orderId
                return a.id - b.id
              })
            }
            // Object.values(item.catalogContent).forEach((subItem) => {
            //   categoryData.push({
            //     ...subItem,
            //     orderId: item.orderid,
            //     parentId: item.parentId,
            //     parentName: item.name,
            //   });
            // });
          });

          // console.log(tree)
          tree.forEach(treeItem => {
            this.traverse(treeItem, categoryData)
          })

          // this.category = categoryData.sort((a, b) => {
          //   if (a.parentId !== b.parentId) return a.parentId - b.parentId
          //   if (a.orderId !== b.orderId) return a.orderId - b.orderId
          //   return a.id - b.id
          // });
          this.category = categoryData
          this.current = categoryData[0];
        })
    },
    handlePrev() {
      if (this.loading) return
      if (this.pageIndex === 0) {
        if (this.currentIndex === 0) return
        this.handleSwitchCurrent(this.currentIndex - 1)
        this.$toast('切换到上一个')
        return
      }
      this.pageIndex -= 1
    },
    handleNext() {
      if (this.loading) return
      if (this.pageIndex + 1 === this.pdfPages) {
        if (this.currentIndex + 1 === this.category.length) return
        this.handleSwitchCurrent(this.currentIndex + 1)
        this.$toast('切换到下一个')
        return
      }
      this.pageIndex += 1
    }
  },
};
</script>

<style lang="scss" scoped>
.preview {
  width: 100vw;
  display: flex;
  align-items: center;
}

.container {
  margin: auto;
  width: 80%;
  max-width: 100vw;

  canvas {
    display: none;
    max-width: 100%;
  }

  .page {
    display: inline-block;
    touch-action: manipulation;
  }

  .pdfViewer {
    height: 100%;
    width: 100%;
  }
}

.menu-btn {
  height: 80px;
  width: 80px;
  background-color: #fff;
  border-radius: 40px;
  border: 1px solid #333;
  position: fixed;
  top: 40px;
  left: 40px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cell {
  font-size: 40px;
  max-width: 50vw;
}

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 30px;
  box-sizing: border-box;

  .page-num {}

  .prev-btn {}

  .next-btn {}
}
</style>